import React from 'react';
import PropTypes from 'prop-types';
import { Button, Drawer, List } from '@mui/material';
import ConnectorsFilterSection from './ConnectorsFilterSection';
import KilowattSpeedFilterSection from './KilowattSpeedFilterSection';
import PriceFilterSection from './PriceFilterSection';

const FiltersDrawer = ({
  showFilters,
  onCloseFilters,
  connectorTypes,
  filteredConnectorTypeId,
  onClearFilterClicked,
  onPriceFilterSelected,
  onConnectorFilterSelected,
  onKilowattSpeedFilterSelected,
  filteredPrice,
  filteredKilowattSpeed
}) => {
  const handleClearFilters = (e) => {
    e.stopPropagation();
    onClearFilterClicked();
    onCloseFilters();
  };

  return (
    <Drawer
      anchor="right"
      open={showFilters}
      onClose={onCloseFilters}
    >
      <List sx={{ margin: 1 }}>
        <PriceFilterSection
          priceType={filteredPrice}
          onPriceFilterSelected={onPriceFilterSelected}
        />
        <ConnectorsFilterSection 
          connectorTypes={connectorTypes}
          filteredConnectorTypeId={filteredConnectorTypeId}
          onConnectorFilterSelected={onConnectorFilterSelected}
        />
        <KilowattSpeedFilterSection
          filteredKilowattSpeed={filteredKilowattSpeed}
          onKilowattSpeedFilterSelected={onKilowattSpeedFilterSelected}
        />
      </List>
      <Button 
        sx={{ margin: 2 }} 
        onClick={handleClearFilters} 
        variant="contained"
      >
        Clear
      </Button>
    </Drawer>
  );
};

FiltersDrawer.propTypes = {
  showFilters: PropTypes.bool.isRequired,
  onCloseFilters: PropTypes.func.isRequired,
  connectorTypes: PropTypes.array.isRequired,
  filteredConnectorTypeId: PropTypes.string,
  onClearFilterClicked: PropTypes.func.isRequired,
  onPriceFilterSelected: PropTypes.func.isRequired,
  onConnectorFilterSelected: PropTypes.func.isRequired,
  onKilowattSpeedFilterSelected: PropTypes.func.isRequired,
  filteredPrice: PropTypes.string,
  filteredKilowattSpeed: PropTypes.string
};

export default FiltersDrawer;