import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  AppBar,
  Toolbar,
  Typography,
  InputBase,
  Button,
  Box,
  FormControl,
  NativeSelect,
  CircularProgress,
  useMediaQuery,
  IconButton,
  Menu,
  MenuItem
} from '@mui/material';
import { styled, alpha, useTheme } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import MenuIcon from '@mui/icons-material/Menu';
import { KofiButton } from 'react-kofi-button';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25)
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto'
  }
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1.5, 1, 1, 1),
    paddingLeft: `calc(2em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '22ch',
      '&:focus': {
        width: '28ch',
      },
    },
  },
}));

const CountrySelectInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: theme.shape.borderRadius,
    position: 'relative',
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25)
    },
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow'])
  },
}));

const LoadingWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 36,
  height: 36,
  marginLeft: theme.spacing(1),
}));

const NavBar = ({ onSearchButtonTapped, onAboutTapped, onFilterTapped, onCountrySelected, loading }) => {
  const [postcode, setPostcode] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('🇬🇧');
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleSearch = () => {
    onSearchButtonTapped(postcode);
  };

  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
    onCountrySelected();
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (action) => {
    handleMenuClose();
    action();
  };

  return (
    <AppBar position="static">
      <Toolbar>
        {!isMobile && (
          <FormControl sx={{ marginRight: 2 }}>
            <NativeSelect
              id="country-select"
              value={selectedCountry}
              onChange={handleCountryChange}
              input={<CountrySelectInput />}
            >
              <option value="🇬🇧">🇬🇧</option>
              <option value="🌍">🌍</option>
            </NativeSelect>
          </FormControl>
        )}
        <Typography variant="h6" component="div" sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}>
          EV Buddy
        </Typography>
        <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Enter Postcode (NW1 8AF)"
            value={postcode}
            onChange={(e) => setPostcode(e.target.value)}
            onKeyPress={(e) => e.key === 'Enter' && handleSearch()}
          />
        </Search>
        <LoadingWrapper>
          {loading && <CircularProgress color="inherit" size={24} />}
        </LoadingWrapper>
        <Button onClick={handleSearch} variant="contained" sx={{ marginLeft: 1 }}>
          Search
        </Button>
        {isMobile ? (
          <>
            <IconButton
              color="inherit"
              aria-label="menu"
              onClick={handleMenuOpen}
              sx={{ marginLeft: 1 }}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={() => handleMenuItemClick(onFilterTapped)}>Filter</MenuItem>
              <MenuItem onClick={() => handleMenuItemClick(onAboutTapped)}>About</MenuItem>
              <MenuItem onClick={() => handleMenuItemClick(onCountrySelected)}>Change Country</MenuItem>
            </Menu>
          </>
        ) : (
          <>
            <Button onClick={onFilterTapped} variant="contained" sx={{ marginLeft: 1 }}>
              Filter
            </Button>
            <Button onClick={onAboutTapped} variant="contained" sx={{ marginLeft: 1 }}>
              About
            </Button>
            <Box sx={{ marginLeft: 1 }}>
              <KofiButton username="DarrylBayliss" label="Support This Site" backgroundColor="kofiRed" />
            </Box>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

NavBar.propTypes = {
  onSearchButtonTapped: PropTypes.func.isRequired,
  onAboutTapped: PropTypes.func.isRequired,
  onFilterTapped: PropTypes.func.isRequired,
  onCountrySelected: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default NavBar;