import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Unstable_Grid2';
import { Button } from '@mui/material';
import ConnectorsFilterSection from './ConnectorsFilterSection';
import KilowattSpeedFilterSection from './KilowattSpeedFilterSection';
import PriceFilterSection from './PriceFilterSection';

const Filters = ({
  onCloseFilters,
  connectorTypes,
  filteredConnectorTypeId,
  onClearFilterClicked,
  onPriceFilterSelected,
  onConnectorFilterSelected,
  onKilowattSpeedFilterSelected,
  filteredPrice,
  filteredKilowattSpeed
}) => {
  const handleClearFilters = (e) => {
    e.stopPropagation();
    onClearFilterClicked();
    onCloseFilters();
  };

  return (
    <Grid container rowSpacing={1} columnSpacing={1}>
      <Grid xs={12}>
        <PriceFilterSection
          priceType={filteredPrice}
          onPriceFilterSelected={onPriceFilterSelected}
        />
      </Grid>
      <Grid xs={12}>
        <ConnectorsFilterSection 
          connectorTypes={connectorTypes}
          filteredConnectorTypeId={filteredConnectorTypeId}
          onConnectorFilterSelected={onConnectorFilterSelected}
        />
      </Grid>
      <Grid xs={12}>
        <KilowattSpeedFilterSection
          filteredKilowattSpeed={filteredKilowattSpeed}
          onKilowattSpeedFilterSelected={onKilowattSpeedFilterSelected}
        />
      </Grid>
      <Grid xs={12}>
        <Button 
          sx={{ margin: 2, width: '100%' }} 
          onClick={handleClearFilters} 
          variant="contained"
        >
          Clear
        </Button>
      </Grid>
    </Grid>
  );
};

Filters.propTypes = {
  showFilters: PropTypes.bool.isRequired,
  onCloseFilters: PropTypes.func.isRequired,
  connectorTypes: PropTypes.array.isRequired,
  filteredConnectorTypeId: PropTypes.string,
  onClearFilterClicked: PropTypes.func.isRequired,
  onPriceFilterSelected: PropTypes.func.isRequired,
  onConnectorFilterSelected: PropTypes.func.isRequired,
  onKilowattSpeedFilterSelected: PropTypes.func.isRequired,
  filteredPrice: PropTypes.string,
  filteredKilowattSpeed: PropTypes.string
};

export default Filters;